import React from 'react'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
  Image,
  Button,
  Link,
} from '@chakra-ui/react'
import checkCircleSolid from 'img/check-circle-solid-success.svg'
import errorCircleSolid from 'img/error-circle-solid.svg'
import { DownloadDateRangeJobStatus } from 'services/RedeemApi/types'

type JobCompletedProps = {
  jobState: DownloadDateRangeJobStatus
  closeAndResetModalState: () => void
}

const JobCompleted = ({
  jobState,
  closeAndResetModalState,
}: JobCompletedProps): JSX.Element => {
  return (
    <>
      <ModalHeader minHeight="64px" />
      <ModalBody paddingY="40px">
        <VStack alignItems="left" justifyContent="space-between" spacing="28px">
          {jobState === DownloadDateRangeJobStatus.success ? (
            <>
              <Image width="40px" height="40px" src={checkCircleSolid} />
              <VStack alignItems="left">
                <Text textStyle="h3" textColor="primary.500">
                  Exported successfully
                </Text>
                <Text textStyle="body1" textColor="neutral.700">
                  Your report should be downloading right now.
                </Text>
              </VStack>
            </>
          ) : null}
          {jobState === DownloadDateRangeJobStatus.failure ? (
            <>
              <Image width="40px" height="40px" src={errorCircleSolid} />
              <VStack alignItems="left" spacing="16px">
                <Text textStyle="h2" paddingTop="8px" textColor="primary.500">
                  Failed to export
                </Text>
                <Text textStyle="body1" textColor="neutral.700">
                  {`If this issue persists, please take a screenshot and email us at `}
                  <Link href="mailto:team@redeem.gov.sg" variant="subhead">
                    team@redeem.gov.sg
                  </Link>
                  {` for support.`}
                </Text>
              </VStack>
            </>
          ) : null}
        </VStack>
      </ModalBody>
      <ModalFooter marginLeft="auto" padding="0px 48px 36px 48px">
        <Button onClick={closeAndResetModalState}>Close</Button>
      </ModalFooter>
    </>
  )
}

export default JobCompleted
