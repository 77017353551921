import { Avatar } from './Avatar'
import { Button } from './Button'
import { Checkbox } from './Checkbox'
import { Divider } from './Divider'
import { Drawer } from './Drawer'
import { Infobox } from './Infobox'
import { Input } from './Input'
import { Link } from './Link'
import { Menu } from './Menu'
import { Modal } from './Modal'
import { Progress } from './Progress'
import { Radio } from './Radio'
import { Select } from './Select'
import { Stat } from './Stat'
import { Steps } from './Steps'
import { Switch } from './Switch'
import { Table } from './Table'
import { Tabs } from './Tabs'
import { Tooltip } from './Tooltip'
import { Attachment } from './Attachment'
import { Toast } from './Toast'
import { Banner } from './Banner'

export const components = {
  Attachment,
  Avatar,
  Banner,
  Button,
  Checkbox,
  Divider,
  Drawer,
  Infobox,
  Input,
  Link,
  Menu,
  Modal,
  Progress,
  Radio,
  Select,
  Stat,
  Steps,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Toast,
}
