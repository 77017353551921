import React, { useEffect } from 'react'
import _ from 'lodash'
import {
  Button,
  ButtonGroup,
  VStack,
  Text,
  Flex,
  Image,
} from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import FormInput from 'components/FormInput'
import singaporeFlag from 'img/singapore.png'
import { useVouchersTabContext } from '../../../VouchersTabContext'
import StepCard from './common/StepCard'
import { VOUCHER_TYPES } from '../constants'
import { CreateVoucherStoredFormContent } from '../types'

type PersonalDetailsStepProps = {
  storedFormContent: CreateVoucherStoredFormContent
  onAddFormContent: (data: Partial<CreateVoucherStoredFormContent>) => void
  setStoredFormContent: React.Dispatch<
    React.SetStateAction<CreateVoucherStoredFormContent>
  >
}

const PersonalDetailsStep = ({
  storedFormContent,
  onAddFormContent,
  setStoredFormContent,
}: PersonalDetailsStepProps) => {
  const { advanceStep, selectedVoucherType } = useVouchersTabContext()

  const formMethods = useForm()
  const {
    handleSubmit,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = formMethods
  const hasErrors = !_.isEmpty(errors)

  const {
    voucherContactNumberParam: storedContactNumber,
    voucherNameParam: storedName,
  } = storedFormContent

  const onPrimaryClick = (data: Partial<CreateVoucherStoredFormContent>) => {
    onAddFormContent(data)
    advanceStep(1)
  }

  const isSMSVoucherType = selectedVoucherType === VOUCHER_TYPES.DIGITAL
  const contactNumber = watch('voucherContactNumberParam')
  const name = watch('voucherNameParam')

  useEffect(() => {
    setValue('voucherContactNumberParam', storedContactNumber)
    setValue('voucherNameParam', storedName)
  }, [])

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onPrimaryClick)}>
        <StepCard
          header="Personal details"
          cardBody={
            <VStack align="start" width="100%" spacing="40px">
              <FormInput
                name="voucherNameParam"
                label="Name"
                inputProps={{
                  placeholder: 'John Doe',
                  id: 'create-vouchers-name-input',
                  autoFocus: true,
                }}
              />
              <FormInput
                name="voucherContactNumberParam"
                label={
                  <>
                    <Flex alignItems="center">
                      Mobile number
                      {selectedVoucherType === VOUCHER_TYPES.PAPER && (
                        <Text
                          textStyle="body2"
                          marginLeft="8px"
                          color="neutral.700"
                        >
                          (optional)
                        </Text>
                      )}
                    </Flex>
                    {selectedVoucherType === VOUCHER_TYPES.PAPER && (
                      <Text textStyle="body2" color="neutral.700">
                        Leave mobile number blank if resident does not have a
                        phone
                      </Text>
                    )}
                  </>
                }
                inputProps={{
                  placeholder: '91234567',
                  id: 'create-vouchers-mobile-number-input',
                }}
                registerOptions={{
                  required:
                    selectedVoucherType === VOUCHER_TYPES.DIGITAL
                      ? 'Please enter a valid phone number'
                      : false,
                  maxLength: {
                    value: 8,
                    message: 'Please enter a valid 8 digit phone number',
                  },
                  minLength: {
                    value: 8,
                    message: 'Please enter a valid 8 digit phone number',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Please enter a valid phone number',
                  },
                }}
                inputRightElement={
                  <Image height="16px" paddingRight="2px" src={singaporeFlag} />
                }
                customOnBlur={() => trigger('voucherContactNumberParam')}
              />
            </VStack>
          }
          cardFooter={
            <ButtonGroup>
              <Button
                id="personal-details-step-back"
                onClick={() => {
                  setStoredFormContent((previousFormContent) => ({
                    ...previousFormContent,
                    voucherContactNumberParam: contactNumber,
                    voucherNameParam: name,
                  }))
                  advanceStep(-1)
                }}
                variant="neutralOutline"
              >
                Back
              </Button>
              <Button
                id="personal-details-step-next-button"
                isDisabled={(isSMSVoucherType && !contactNumber) || hasErrors}
                type="submit"
              >
                Next
              </Button>
            </ButtonGroup>
          }
        />
      </form>
    </FormProvider>
  )
}

export default PersonalDetailsStep
