import React from 'react'
import { HStack, Text } from '@chakra-ui/react'
import { isVoided } from 'helpers/utils'
import NricDisplay from 'components/NricDisplay'

type GroupNricCellBodyProps = {
  group: any // TODO: Stricter typing
}

const GroupNricCellBody: React.FC<GroupNricCellBodyProps> = ({ group }) => {
  return (
    <HStack display="flex" spacing="8px">
      <Text>
        <NricDisplay nric={group?.nric} fallbackElement="-" />
      </Text>
      {isVoided(group) && (
        <Text
          textStyle="caption1"
          padding="4px"
          color="primary.100"
          background="neutral.700"
          borderRadius="4px"
        >
          Void
        </Text>
      )}
    </HStack>
  )
}

export default GroupNricCellBody
