import { ComponentStyleConfig } from '@chakra-ui/react'

// currently hardcoded since no other component has this
export const Progress: ComponentStyleConfig = {
  baseStyle: {
    filledTrack: {
      bgColor: 'primary.500',
      borderRadius: '4px',
    },
    track: {
      bgColor: 'neutral.300',
      borderRadius: '4px',
    },
  },
}
