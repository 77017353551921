import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import {
  InvalidNricError,
  splitNricForMasking,
  SplittedNricForMasking,
} from 'helpers/nric'
import MaskedOnMonitoring from 'components/MaskedOnMonitoring'

type NricDisplayProps = {
  id?: string
  nric?: string | null
  fallbackElement?: React.ReactChild
  color?: string
  textStyle?: string
}

const NricDisplay = ({
  id,
  nric,
  fallbackElement,
  color,
  textStyle,
}: NricDisplayProps) => {
  const shouldMask = !!nric?.length
  let splittedNricForMasking: SplittedNricForMasking | null = null
  try {
    splittedNricForMasking = shouldMask ? splitNricForMasking(nric) : null
  } catch (e) {
    // Ignore if NRIC invalid, Just don't mask
    if (!(e instanceof InvalidNricError)) {
      throw e
    }
  }
  // If nric field cannot be masked for any reason
  if (!splittedNricForMasking) {
    if (!nric?.length && fallbackElement) {
      // Nric field is blank and empty element is configured, render empty element
      // Fragment to convert to react element
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{fallbackElement}</>
    }

    // Otherwise, just show nric in full
    return (
      <Text as="span" id={id}>
        {nric}
      </Text>
    )
  }

  // Otherwise, mask
  return (
    <Box as="span" textStyle={textStyle} display="inline" color={color} id={id}>
      <MaskedOnMonitoring>
        <Text as="span" id="masked-nric-component">
          {splittedNricForMasking.toMask}
        </Text>
      </MaskedOnMonitoring>
      <Text as="span" id="unmasked-nric-component">
        {splittedNricForMasking.toShow}
      </Text>
    </Box>
  )
}

export default NricDisplay
