import './styles/DateRangePickerWithInput.scss'

import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { RangeKeyDict, Range, DateRange } from 'react-date-range'
import moment from 'moment'
import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Text,
  VStack,
  Image,
} from '@chakra-ui/react'
import { BiCalendar } from 'react-icons/bi'
import errorCircleSolid from 'img/error-circle-solid.svg'

function formatDate(date: Date): string {
  return moment(date).format('DD/MM/YYYY')
}

type DateRangePickerWithInputProps = {
  minDate: Date
  todayDate: Date
  ranges: Range[]
  setRanges: (ranges: Range[]) => void
  inputValue: string
  setInputValue: (value: string) => void
  showRangeError: boolean
  setShowRangeError: (value: boolean) => void
  showNoEntriesError: string
  setShowNoEntriesError: (value: string) => void
}

const DateRangePickerWithInput: React.FC<DateRangePickerWithInputProps> = ({
  minDate,
  todayDate,
  ranges,
  setRanges,
  inputValue,
  setInputValue,
  showRangeError,
  setShowRangeError,
  showNoEntriesError,
  setShowNoEntriesError,
}) => {
  const [showCalendar, setShowCalendar] = useState(false)
  const [lastSelectedDate, setLastSelectedDate] = useState<Date | null>(null)
  const calendarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setShowCalendar(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const onSelectRanges = (rangesByKey: RangeKeyDict) => {
    const { selection } = rangesByKey
    const newStartDate = selection.startDate
    const newEndDate = selection.endDate

    const diffInDays = moment(newEndDate).diff(moment(newStartDate), 'days')

    // If the difference in days is greater than 31, show an error message
    if (diffInDays >= 31) {
      setShowCalendar(false)
      setShowRangeError(true)
    } else if (newStartDate && newEndDate) {
      setShowNoEntriesError('')
      setShowRangeError(false)
      setRanges([selection])
      setLastSelectedDate(newEndDate)
      setInputValue(`${formatDate(newStartDate)} - ${formatDate(newEndDate)}`)

      // If the new end date is greater than the new start date, close the calendar
      // OR if the new start date is the same as the last selected date, close the calendar
      if (
        moment(newEndDate).isAfter(newStartDate) ||
        (newStartDate && moment(newStartDate).isSame(lastSelectedDate))
      ) {
        setShowCalendar(false)
        setLastSelectedDate(null)
      }
    }
  }

  return (
    <VStack alignItems="start" align="stretch" spacing="8px">
      <Text textStyle="subhead1" textColor="neutral.900">
        Select date range
      </Text>
      <Box ref={calendarRef} position="relative" width="100%">
        <InputGroup onClick={() => setShowCalendar(!showCalendar)}>
          <Input minHeight="36px" isReadOnly value={inputValue} />
          <InputRightElement>
            <Icon as={BiCalendar} size="20px" />
          </InputRightElement>
        </InputGroup>
        {showCalendar && (
          <Box
            position="absolute"
            zIndex={9999}
            top="45px"
            left={0}
            boxShadow="md"
          >
            <DateRange
              color="#505798"
              ranges={ranges}
              rangeColors={['#505798', '#505798', '#505798']}
              onChange={onSelectRanges}
              showDateDisplay={false}
              minDate={minDate}
              maxDate={todayDate}
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"
              calendarFocus="backwards"
            />
          </Box>
        )}
      </Box>
      <Text textStyle="body2" textColor="neutral.700">
        To generate a daily report, select the same date for both start and end.
      </Text>
      {showRangeError && (
        <HStack alignItems="top">
          <Image
            width="16px"
            height="16px"
            marginTop="2px"
            src={errorCircleSolid}
          />
          <Text textStyle="body2" color="danger.500">
            You can export up to a maximum of 31 days. Try selecting a smaller
            range.
          </Text>
        </HStack>
      )}
      {!_.isEmpty(showNoEntriesError) && (
        <HStack alignItems="top">
          <Image
            width="16px"
            height="16px"
            marginTop="2px"
            src={errorCircleSolid}
          />
          <Text textStyle="body2" color="danger.500">
            {showNoEntriesError}
          </Text>
        </HStack>
      )}
    </VStack>
  )
}

export default DateRangePickerWithInput
