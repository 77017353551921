import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Text, VStack, Link } from '@chakra-ui/react'

type TabErrorBoundary = {
  children: React.ReactNode
  tabName: string
}

const TabErrorBoundary = ({ children, tabName }: TabErrorBoundary) => {
  return (
    <ErrorBoundary
      fallback={
        <VStack align="start" margin="32px">
          <Text textStyle="h2">Something went wrong...</Text>
          <Text>
            {`We are currently unable to display ${tabName} currently. Please contact `}
            <Link href="mailto:team@redeem.gov.sg" variant="subhead">
              team@redeem.gov.sg
            </Link>
          </Text>
        </VStack>
      }
    >
      {children}
    </ErrorBoundary>
  )
}

export default TabErrorBoundary
