import React from 'react'
import sgCrest from 'img/sg-crest.svg'
import styles from './Masthead.module.scss'

function Masthead() {
  return (
    <div className={styles.mastheadContainer}>
      <div className="row">
        <div className="col-12">
          <div className={styles.mastheadContent}>
            <img src={sgCrest} className={styles.sgCrest} alt="" />
            <p>A Singapore Government Website</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Masthead
