import axios from 'axios'

const NETWORK_ERROR = 'Network Error'

export function isNetworkError(err: unknown) {
  return axios.isAxiosError(err) && err.message === NETWORK_ERROR
}

//
/**
 * Reference from https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
 * Returns the online status of the browser. The property returns a boolean value, with true
 * meaning online and false meaning offline. The property sends updates whenever the browser's ability to
 * connect to the network changes. The update occurs when the user follows links or when a script requests
 * a remote page. For example, the property should return false when users click links soon after they
 * lose internet connection.
 * Browsers implement this property differently.
 * In Chrome and Safari, if the browser is not able to connect to a local area network (LAN) or a router,
 * it is offline; all other conditions return true. So while you can assume that the browser is offline
 * when it returns a false value, you cannot assume that a true value necessarily means that the browser
 * can access the internet. You could be getting false positives, such as in cases where the computer is
 * running a virtualization software that has virtual ethernet adapters that are always "connected."
 * Therefore, if you really want to determine the online status of the browser, you should develop
 * additional means for checking.
 *
 * Thus onLine doesn't 100% guarantee that even if this returns true, it means that the user have internet connection.
 * Technically, it is possible to use axios to make an api request to your own favicon / image, to see if it return false when onLine is true
 * but such complexity is not needed unless we need a situation whereby we only really want to make a request when there is internet connection
 */
export function isTherePossibleInternetConnection() {
  return navigator.onLine
}
