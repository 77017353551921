import { useCampaignReports } from 'hooks/CampaignReports'
import React from 'react'
import { useCampaignContext } from 'routes/Campaigns/Campaign/context/CampaignContext'
import DownloadReportModal from 'components/DownloadReportModal'
import { DownloadDateRangeReportType } from 'components/DownloadReportModal/types'
import { useVouchersTabContext } from '../../VouchersTabContext'

const DownloadTransactionsReportContainer = () => {
  const { campaignId } = useCampaignContext()
  const {
    isDownloadTransactionsModalOpen: isOpen,
    onCloseDownloadTransactionsModal: onClose,
  } = useVouchersTabContext()

  const { campaignReports } = useCampaignReports({
    campaignId,
    reportType: DownloadDateRangeReportType.TRANSACTIONS,
    onOpenModal: null,
  })

  return (
    <DownloadReportModal
      isOpen={isOpen}
      onClose={onClose}
      reports={campaignReports}
      reportType={DownloadDateRangeReportType.TRANSACTIONS}
      campaignId={campaignId}
    />
  )
}

export default DownloadTransactionsReportContainer
