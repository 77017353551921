export enum DownloadReportStage {
  SINGLE_DAY,
  MULTI_DAY,
  GENERATING,
  SUCCESS,
  FAILURE,
}

export enum DownloadDateRangeReportType {
  TRANSACTIONS = 'transactions',
  SETTLEMENTS = 'settlements',
}
