import React, { useState } from 'react'

import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'

import { DownloadDateRangeJobStatus } from 'services/RedeemApi/types'
import { DownloadDateRangeReportType, DownloadReportStage } from './types'
import DownloadDateRangeReportSelectRange from './components/DownloadDateRangeReportSelectRange'
import DownloadDateRangeReportSubmitted from './components/DownloadDateRangeReportSubmitted'
import JobCompleted from './components/DownloadDateRangeReportSubmitted/components/JobCompleted'
import DownloadSingleReport from './components/DownloadSingleReport'

type DownloadDateRangeReportModalProps = {
  isOpen: boolean
  onClose: () => void
  reports: any[] // TODO: Refactor to better type
  reportType: DownloadDateRangeReportType
  campaignId: string
}

const DownloadDateRangeReportModal = ({
  isOpen,
  onClose,
  reports,
  reportType,
  campaignId,
}: DownloadDateRangeReportModalProps) => {
  const [jobId, setJobId] = useState('')

  const [downloadReportStage, setDownloadReportStage] = useState<
    DownloadReportStage
  >(DownloadReportStage.MULTI_DAY)

  const closeModalAndResetState = () => {
    onClose()
    setJobId('')
    setDownloadReportStage(DownloadReportStage.MULTI_DAY)
  }

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      colorScheme="primary"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent minWidth="680px">
        {downloadReportStage === DownloadReportStage.SINGLE_DAY && (
          <DownloadSingleReport
            onClose={closeModalAndResetState}
            reportType={reportType}
            reports={reports}
            campaignId={campaignId}
          />
        )}
        {downloadReportStage === DownloadReportStage.MULTI_DAY && (
          <DownloadDateRangeReportSelectRange
            onClose={closeModalAndResetState}
            reportType={reportType}
            campaignId={campaignId}
            setDownloadReportStage={setDownloadReportStage}
            setJobId={setJobId}
          />
        )}
        {/* 
          Single date range download:
          i) Success state
          ii) Without jobId
        */}
        {downloadReportStage === DownloadReportStage.SUCCESS && !jobId ? (
          <JobCompleted
            jobState={DownloadDateRangeJobStatus.success}
            closeAndResetModalState={closeModalAndResetState}
          />
        ) : null}
        {/* 
          Multi date range download:
          i) Generating state
          ii) With jobId
        */}
        {downloadReportStage === DownloadReportStage.GENERATING && !!jobId ? (
          <DownloadDateRangeReportSubmitted
            reportType={reportType}
            jobId={jobId}
            closeAndResetModalState={closeModalAndResetState}
          />
        ) : null}
      </ModalContent>
    </Modal>
  )
}

export default DownloadDateRangeReportModal
