import React from 'react'
import { DownloadDateRangeReportType } from 'components/DownloadReportModal/types'
import { useDownloadCampaignReport } from 'hooks/CampaignReports'
import DownloadReportRow from './DownloadReportRow'

type DownloadReportRowContainerProps = {
  reportType: DownloadDateRangeReportType
  report: any // TODO: Stricter typing
  campaignId: string
}

const DownloadReportRowContainer = ({
  reportType,
  report,
  campaignId,
}: DownloadReportRowContainerProps) => {
  const {
    downloadCampaignReport,
    isDownloadCampaignReportLoading,
  } = useDownloadCampaignReport({ campaignId, key: report.key, reportType })
  return (
    <DownloadReportRow
      date={report.reportDate}
      onClickPrimary={downloadCampaignReport}
      isPrimaryLoading={isDownloadCampaignReportLoading}
    />
  )
}

export default DownloadReportRowContainer
