import React from 'react'
import _ from 'lodash'

import {
  VStack,
  Box,
  ModalBody,
  ModalHeader,
  Table,
  Text,
  Thead,
  Tr,
  Th,
  Tbody,
} from '@chakra-ui/react'
import ModalCloseButton from 'components/ModalCloseButton'
import { DownloadDateRangeReportType } from 'components/DownloadReportModal/types'
import DownloadReportRowContainer from './components/DownloadReportRowContainer'

type DownloadSingleReportProps = {
  onClose: () => void
  reportType: DownloadDateRangeReportType
  reports: any[]
  campaignId: string
}

const DownloadSingleReport = ({
  onClose,
  reportType,
  reports,
  campaignId,
}: DownloadSingleReportProps) => {
  return (
    <>
      <ModalCloseButton onClick={onClose} />
      <ModalHeader minHeight="64px" />
      <ModalBody paddingY="48px">
        <VStack align="stretch" spacing="40px">
          <Text textStyle="h2" color="primary.700">
            {`${_.startCase(reportType)} reports`}
          </Text>
          <Table>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th width="4.25rem">
                  <Box width="4.25rem" />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {_.map(reports, (report) => (
                <DownloadReportRowContainer
                  key={report.key}
                  reportType={reportType}
                  report={report}
                  campaignId={campaignId}
                />
              ))}
            </Tbody>
          </Table>
        </VStack>
      </ModalBody>
    </>
  )
}

export default DownloadSingleReport
