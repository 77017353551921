import { useCallback } from 'react'
import { checkIfAddressInBlacklistForCampaign } from 'services/RedeemApi'
import { CampaignEligibility } from 'services/RedeemApi/types'
import { CheckIfAddressInBlacklistForCampaignParams } from './types'

export function useCheckIfAddressInBlacklistForCampaign({
  campaignEligibility,
  campaignId,
}: {
  campaignEligibility: CampaignEligibility
  campaignId: string
}) {
  return useCallback(
    async (address: CheckIfAddressInBlacklistForCampaignParams) => {
      if (
        campaignEligibility === CampaignEligibility.no_signup ||
        campaignEligibility === CampaignEligibility.signup_whitelist_nric
      ) {
        return false
      }
      if (address === null) {
        return false
      }

      try {
        const response = await checkIfAddressInBlacklistForCampaign({
          ...address,
          campaignId,
        })

        return !!response?.result
      } catch (err) {
        return true
      }
    },
    [campaignEligibility, campaignId]
  )
}
