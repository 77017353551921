import React from 'react'

function NoMatch() {
  return (
    <div className="container">
      <h1>404 page</h1>
    </div>
  )
}

export default NoMatch
