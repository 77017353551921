import axios, {
  AxiosError,
  AxiosRequestTransformer,
  AxiosResponseTransformer,
} from 'axios'
import axiosRetry from 'axios-retry'
import humps from 'humps'
import qs from 'qs'

import { loadUser } from 'services/Storage'
import { isNetworkError, isTherePossibleInternetConnection } from './helper'

const { REACT_APP_REDEEM_URL: REDEEM_URL } = process.env

// Casting of transformers is a known issue for Axios: https://github.com/axios/axios/issues/1910

// Config to snake_case outgoing post body and query strings, and camelCase incoming
// responses
export const baseConfiguration = {
  // Configure base url
  baseURL: `${REDEEM_URL}/v1`,
  // Inject device headers into all outgoing requests.
  // headers: someHeaders,
  // Add our own transformation after the default setting transform
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    (data: object[]) => humps.camelizeKeys(data),
  ],
  // Add our transformation before passing to default setting to transform
  transformRequest: [
    (data: object[]) => humps.decamelizeKeys(data),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  paramsSerializer: (params: object[]) => {
    return qs.stringify(humps.decamelizeKeys(params))
  },
}

// Helper to load user and read the token and craft the Auth Header
export function getAuthorizationHeader() {
  const user = loadUser()
  const accessToken = user.token
  return { Authorization: `Bearer ${accessToken}` }
}

// Create the api service which operational needs calls
const ApiService = axios.create(baseConfiguration)

// Hook axios retry interceptor to base axios. Removing default retries, such that only if
// the request requires retries, then the exact request should add retry config
axiosRetry(ApiService, {
  retries: 0,
  // Exponential backoff factor of 1s instead of 100ms. https://github.com/softonic/axios-retry/blob/603b73cffd88f895e0f784128cfbe69b129aedc6/src/index.ts#L161
  retryDelay: (retryCount: number, error: AxiosError) =>
    axiosRetry.exponentialDelay(retryCount, error, 1000),
  retryCondition: (error) => {
    // Currently only want to support network related issues. Can be extended in the future
    // We will only retry when it is clear that the user definitely not have internet connection as the point of firing the request.
    return isNetworkError(error) && !isTherePossibleInternetConnection()
  },
})

export default ApiService
