import React, { useCallback, useState } from 'react'

import {
  Button,
  HStack,
  ModalHeader,
  ModalBody,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Range } from 'react-date-range'
import moment from 'moment'

import ModalCloseButton from 'components/ModalCloseButton'
import DateRangePickerWithInput from 'components/DateRangePickerWithInput/DateRangePickerWithInput'

import { useSubmitDownloadDateRangeReport } from 'hooks/CampaignReports'
import { useCampaignContext } from 'routes/Campaigns/Campaign/context/CampaignContext'

import { DownloadDateRangeReportStatus } from 'services/RedeemApi/types'
import {
  DownloadDateRangeReportType,
  DownloadReportStage,
} from 'components/DownloadReportModal/types'

function formatDate(date: Date): string {
  return moment(date).format('DD/MM/YYYY')
}

function getMaxDate(reportType: DownloadDateRangeReportType) {
  const todayDate = new Date()
  switch (reportType) {
    case DownloadDateRangeReportType.SETTLEMENTS:
      return todayDate
    case DownloadDateRangeReportType.TRANSACTIONS:
      return moment(todayDate).subtract(1, 'day').toDate()
    default:
      throw new Error('Invalid report type')
  }
}

type DownloadReportSelectRangeProps = {
  onClose: () => void
  reportType: DownloadDateRangeReportType
  campaignId: string
  setDownloadReportStage: (state: DownloadReportStage) => void
  setJobId: (jobId: string) => void
}

const DownloadDateRangeReportSelectRange = ({
  onClose,
  reportType,
  campaignId,
  setDownloadReportStage,
  setJobId,
}: DownloadReportSelectRangeProps): JSX.Element => {
  const { campaignValidityStart } = useCampaignContext()
  const maxDate = getMaxDate(reportType)

  const [ranges, setRanges] = useState<Range[]>([
    {
      startDate: maxDate,
      endDate: maxDate,
      key: 'selection',
    },
  ])
  const [inputValue, setInputValue] = useState(
    `${formatDate(maxDate)} - ${formatDate(maxDate)}`
  )

  // This is used to show:
  // i) range error > 31 days message within date range picker component
  // ii) disable the export button on this error
  const [showRangeError, setShowRangeError] = useState(false)
  const [showNoEntriesError, setShowNoEntriesError] = useState('')

  const {
    submitDownloadDateRangeReport,
    isSubmitDownloadDateRangeReportLoading,
  } = useSubmitDownloadDateRangeReport({
    reportType,
    campaignId,
    startDate: moment(ranges[0]?.startDate).format('YYYY-MM-DD'),
    endDate: moment(ranges[0]?.endDate).format('YYYY-MM-DD'),
    setShowNoEntriesError,
  })

  const onSubmitDownloadDateRangeReport = useCallback(async () => {
    const response = await submitDownloadDateRangeReport()

    if (response.status === DownloadDateRangeReportStatus.completed) {
      // If this returns true straight, it means that it's a success straight
      setDownloadReportStage(DownloadReportStage.SUCCESS)
    } else {
      setJobId(response.jobId)
      setDownloadReportStage(DownloadReportStage.GENERATING)
    }
  }, [submitDownloadDateRangeReport])

  return (
    <>
      <ModalCloseButton onClick={onClose} />
      <ModalHeader minHeight="64px" />
      <ModalBody position="relative" paddingY="48px">
        <VStack align="stretch" spacing="28px">
          <VStack alignItems="start">
            <Text textStyle="h3" textColor="primary.500">
              {`Export ${reportType} reports`}
            </Text>
            <Text textStyle="body1" textColor="neutral.700">
              You can export up to a maximum of 31 days.
            </Text>
          </VStack>
          <DateRangePickerWithInput
            minDate={new Date(campaignValidityStart)}
            todayDate={maxDate}
            ranges={ranges}
            setRanges={setRanges}
            inputValue={inputValue}
            setInputValue={setInputValue}
            showRangeError={showRangeError}
            setShowRangeError={setShowRangeError}
            showNoEntriesError={showNoEntriesError}
            setShowNoEntriesError={setShowNoEntriesError}
          />
          <HStack justifyContent="end" spacing="20px">
            <Button onClick={onClose} variant="clear">
              Cancel
            </Button>
            <Button
              isDisabled={showRangeError}
              isLoading={isSubmitDownloadDateRangeReportLoading}
              onClick={onSubmitDownloadDateRangeReport}
            >
              Export
            </Button>
          </HStack>
        </VStack>
      </ModalBody>
    </>
  )
}

export default DownloadDateRangeReportSelectRange
