export const PERMISSION_GROUPS = {
  operations: {
    label: 'Manage vouchers',
    description: 'Create, send and print vouchers',
  },
  finance: {
    label: 'Download finance reports',
    description: 'Download payout and settlement reports',
  },
  admin: {
    label: 'Manage campaign',
    description:
      'View and edit campaign settings, add and remove users, view campaign statistics, view security history and download voucher reports',
  },
}
