import _ from 'lodash'
import type { ValidationResult } from './types'

export type SplittedNricForMasking = {
  toMask: string
  toShow: string
}

export class InvalidNricError extends Error {
  nric: string

  reason: string

  constructor({ nric, reason }: { nric: string; reason: string }) {
    super(`Invalid NRIC: ${nric}. Reason: ${reason}`)
    this.nric = nric
    this.reason = reason
  }
}

/**
 * Logic for NRIC is referenced from https://github.com/samliew/singapore-nric
 */

type NricFirstLetterKeys = 'S' | 'T' | 'F' | 'G' | 'M'

const CHAR_TO_WEIGHT: Record<NricFirstLetterKeys, number> = {
  T: 4,
  G: 4,
  M: 3,
  S: 0,
  F: 0,
}

const checksumArrayVariantSAndT = [
  'J',
  'Z',
  'I',
  'H',
  'G',
  'F',
  'E',
  'D',
  'C',
  'B',
  'A',
] as const
const checksumArrayVariantFAndG = [
  'X',
  'W',
  'U',
  'T',
  'R',
  'Q',
  'P',
  'N',
  'M',
  'L',
  'K',
] as const
const checksumArrayVariantM = [
  'K',
  'L',
  'J',
  'N',
  'P',
  'Q',
  'R',
  'T',
  'U',
  'W',
  'X',
] as const

type AllowedChecksumArray =
  | typeof checksumArrayVariantSAndT
  | typeof checksumArrayVariantFAndG
  | typeof checksumArrayVariantM

const CHAR_TO_CHECKSUM_ARRAY: Record<
  NricFirstLetterKeys,
  AllowedChecksumArray
> = {
  S: checksumArrayVariantSAndT,
  T: checksumArrayVariantSAndT,
  F: checksumArrayVariantFAndG,
  G: checksumArrayVariantFAndG,
  M: checksumArrayVariantM,
} as const

const NRIC_FIRST_CHARS: ReadonlyArray<NricFirstLetterKeys> = [
  'S',
  'T',
  'F',
  'G',
  'M',
] as const

function isNricFirstLetterValid(char: string): char is NricFirstLetterKeys {
  return _.includes(NRIC_FIRST_CHARS, char)
}

// This is a reference of redeem-api validateNric but a variant of it. The api one is more complex due to its requirement for error messages
function validateNricConditions(nric: string): ValidationResult {
  // The empty check now doesnt come before this capitalisation check anymore
  if (nric?.toUpperCase() !== nric) {
    return {
      valid: false,
      reason: 'must not contain lower-cased characters',
    }
  }

  if (nric.length !== 9) {
    return {
      valid: false,
      reason: 'must be 9 characters long',
    }
  }

  const firstChar = nric.charAt(0)
  const lastChar = nric.charAt(nric.length - 1)

  if (!isNricFirstLetterValid(firstChar)) {
    return {
      valid: false,
      reason: 'First character of NRIC must be "S", "T", "F", "G" or "M"',
    }
  }

  const numberPortionOfNric = nric.substring(1, 8)
  if (!/^\d{7}$/.test(numberPortionOfNric)) {
    return {
      valid: false,
      reason: '2nd to 8th characters of NRIC must be numbers',
    }
  }

  const nricNumbers = _(numberPortionOfNric)
    .split('')
    .map((stringNum) => parseInt(stringNum, 10))
    .value()
  const weightMultipliers = [2, 7, 6, 5, 4, 3, 2]
  const totalWeight = _(nricNumbers)
    .zipWith(weightMultipliers, (n, m) => n * m)
    .reduce((sum, n) => sum + n, 0)

  const additionalWeight = CHAR_TO_WEIGHT[firstChar]
  const remainder = (totalWeight + additionalWeight) % 11
  const index = firstChar === 'M' ? 10 - remainder : remainder
  const validChecksumChar = CHAR_TO_CHECKSUM_ARRAY[firstChar][index]

  if (lastChar !== validChecksumChar) {
    return {
      valid: false,
      reason: 'checksum invalid',
    }
  }

  return { valid: true }
}

// Note that in normal scenario, NRIC which are not capitaliised are still valid. However, in Redeem,
// we standardized that we require NRIC to be fully capitalised.
export function validateNric(nric: string): ValidationResult {
  // Nullity check for nric should come first as values like NaN will cause an error to validateNricCapitalisationCheck
  if (!nric) {
    return {
      valid: false,
      reason: 'must not be empty',
    }
  }

  return validateNricConditions(nric)
}

export function splitNricForMasking(nric: string): SplittedNricForMasking {
  const validationResult = validateNric(nric)
  if (!validationResult.valid) {
    throw new InvalidNricError({ nric, reason: validationResult.reason })
  }

  return {
    toMask: nric.substring(0, 5),
    toShow: nric.substring(5),
  }
}
