import React from 'react'
import Infobox from 'components/Infobox'
import { Text, VStack } from '@chakra-ui/react'
import { openInNewTab } from 'helpers/utils'

const PostmanInfoBox = (): JSX.Element => {
  return (
    <Infobox
      variant="info"
      text={
        <VStack alignItems="left">
          <Text textColor="neutral.900" fontWeight="700">
            Send the links out yourself using your preferred channel
          </Text>
          <Text textStyle="body1" textColor="neutral.700">
            Use{' '}
            <Text
              as="span"
              textDecoration="underline"
              cursor="pointer"
              onClick={() => openInNewTab('https://postman.gov.sg/')}
            >
              Postman
            </Text>{' '}
            to send voucher links via email or SMS, or choose an alternative
            method. Remember, anyone with access to a voucher link will be able
            to spend it.
          </Text>
        </VStack>
      }
    />
  )
}

export default PostmanInfoBox
