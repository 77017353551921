const endpoints: Record<string, Record<string, string>> = {
  signupEndpoint: {
    production: 'https://signup.redeem.gov.sg',
    staging: 'https://signup-staging.redeem.gov.sg',
    development: 'http://localhost:3000',
  },
}

export const signupEndpoint =
  endpoints.signupEndpoint[process.env.REACT_APP_ENV ?? 'development']
