export enum UserActions {
  BulkCreateGroupedVouchers = 'bulkCreateGroupedVouchers',
  CreateGroupedVouchers = 'createGroupedVouchers',
  DownloadCampaignVoucherLinks = 'downloadCampaignVoucherLinks',
  DownloadSettlementReport = 'downloadSettlementReport',
  DownloadTransactionReport = 'downloadTransactionReport',
  DownloadVoucherReport = 'downloadVoucherReport',
  GetCampaignMetrics = 'getCampaignMetrics',
  ListCampaignEvents = 'listCampaignEvents',
  ListGroupedVouchers = 'listGroupedVouchers',
  ListGroupedVoucherEvents = 'listGroupedVoucherEvents',
  ListMerchantEvents = 'listMerchantEvents',
  ListMerchantsForCampaign = 'listMerchantsForCampaign',
  ListSettlementReports = 'listSettlementReports',
  ListTransactionReports = 'listTransactionReports',
  ListUserRolesForCampaign = 'listUserRolesForCampaign',
  PrintGroupedVouchers = 'printGroupedVouchers',
  SendGroupedVouchers = 'sendGroupedVouchers',
  UpdateCampaign = 'updateCampaign',
  UpdateGroupedVoucherContact = 'updateGroupedVoucherContact',
  UpdateMerchant = 'updateMerchant',
  UpdateMerchantPayment = 'updateMerchantPayment',
  UpdateUserRoles = 'updateUserRoles',
}
