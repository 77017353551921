import {
  VStack,
  Box,
  StackProps,
  Stat,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react'
import MaskedOnMonitoring from 'components/MaskedOnMonitoring'
import NricDisplay from 'components/NricDisplay'
import React from 'react'

type RecipientDetailsProps = {
  address?: string
  nric?: string | null
  contactNumber?: string
  name?: string | null
  emptyNricWarningText?: string
} & StackProps

const RecipientDetails: React.FC<RecipientDetailsProps> = ({
  address,
  nric,
  contactNumber,
  name,
  emptyNricWarningText,
  ...stackProps
}) => {
  return (
    <VStack
      sx={{
        '> *': {
          paddingX: '16px',
        },
      }}
      align="start"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="primary.400"
      borderRadius="4px"
      paddingBottom="16px"
      spacing="16px"
      {...stackProps}
    >
      <Box
        width="100%"
        color="primary.700"
        background="primary.200"
        paddingY="8px"
      >
        <Text textStyle="subhead2">Claimed by</Text>
      </Box>
      <Stat size="text">
        <StatLabel>NRIC</StatLabel>
        <StatNumber>
          <NricDisplay
            id="recipient-details-nric-field"
            nric={nric}
            fallbackElement={
              emptyNricWarningText ? (
                <Text as="span" color="danger.500" fontWeight={600}>
                  {emptyNricWarningText}
                </Text>
              ) : (
                '-'
              )
            }
          />
        </StatNumber>
      </Stat>
      <Stat size="text">
        <StatLabel>Registered Address</StatLabel>
        <StatNumber>{address || '-'}</StatNumber>
      </Stat>
      <Stat size="text">
        <StatLabel>Name</StatLabel>
        <StatNumber>{name || '-'}</StatNumber>
      </Stat>
      <Stat size="text">
        <StatLabel>Mobile number</StatLabel>
        <MaskedOnMonitoring>
          <StatNumber id="recipient-details-contact-number-field">
            {contactNumber || '-'}
          </StatNumber>
        </MaskedOnMonitoring>
      </Stat>
    </VStack>
  )
}

export default RecipientDetails
