import { AxiosError } from 'axios'
import { DownloadDateRangeReportType } from 'components/DownloadReportModal/types'
import { useToast } from 'data/Toasts'
import { openInNewTab } from 'helpers/utils'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  checkDownloadDateRangeSettlementReportJobStatus,
  checkDownloadDateRangeTransactionReportJobStatus,
  downloadCampaignVoucherLinks,
  fetchCampaignSettlementReports,
  fetchCampaignSettlementReportURL,
  fetchCampaignTransactionReports,
  fetchCampaignTransactionReportURL,
  fetchCampaignVoucherReportURL,
  submitDownloadDateRangeSettlementReport,
  submitDownloadDateRangeTransactionReport,
} from 'services/RedeemApi'
import {
  DownloadDateRangeJobStatus,
  DownloadDateRangeReportStatus,
} from 'services/RedeemApi/types'

// Refer to useQuery here
// https://react-query.tanstack.com/docs/guides/queries#displaying-background-fetching-loading-states

async function getCampaignReportsAPI({
  reportType,
  campaignId,
}: {
  reportType: DownloadDateRangeReportType
  campaignId: string
}) {
  switch (reportType) {
    case DownloadDateRangeReportType.TRANSACTIONS:
      return fetchCampaignTransactionReports(campaignId)
    case DownloadDateRangeReportType.SETTLEMENTS:
      return fetchCampaignSettlementReports(campaignId)
    default:
      throw new Error('Invalid report type')
  }
}

export function useCampaignReports({
  campaignId,
  reportType,
  onOpenModal,
}: {
  campaignId: string
  reportType: DownloadDateRangeReportType
  onOpenModal: (() => void) | null
}) {
  const { toastErrorWithoutTitle } = useToast()

  const { data, refetch, status, isFetching, error } = useQuery(
    [campaignId, reportType],
    async () => {
      const response = await getCampaignReportsAPI({ reportType, campaignId })
      return response
    },
    {
      enabled: false,
      onSuccess: (successData) => {
        if (successData.totalItems !== 0) {
          onOpenModal?.()
        } else {
          toastErrorWithoutTitle({
            message:
              'Please wait 1 working day after transactions have started to download your report.',
          })
        }
      },
    }
  )

  return {
    campaignReports: data,
    fetchCampaignReports: refetch,
    fetchCampaignReportsStatus: status,
    isFetchingCampaignReports: isFetching,
    fetchCampaignReportsError: error,
  }
}

async function getDownloadCampaignReportAPI({
  reportType,
  campaignId,
  key,
}: {
  reportType: DownloadDateRangeReportType
  campaignId: string
  key: string
}) {
  switch (reportType) {
    case DownloadDateRangeReportType.TRANSACTIONS:
      return fetchCampaignTransactionReportURL({ campaignId, key })
    case DownloadDateRangeReportType.SETTLEMENTS:
      return fetchCampaignSettlementReportURL({ campaignId, key })
    default:
      throw new Error('Invalid report type')
  }
}

export function useDownloadCampaignReport({
  campaignId,
  key,
  reportType,
}: {
  campaignId: string
  key: string
  reportType: DownloadDateRangeReportType
}) {
  const { mutateAsync, status, data, error, reset, isLoading } = useMutation(
    async () => {
      const response = await getDownloadCampaignReportAPI({
        reportType,
        campaignId,
        key,
      })
      const reportUrl = response?.link
      if (reportUrl) {
        openInNewTab(reportUrl)
      }
    }
  )

  return {
    downloadCampaignReport: mutateAsync,
    isDownloadCampaignReportLoading: isLoading,
    downloadCampaignReportStatus: status,
    downloadCampaignReportResponse: data,
    downloadCampaignReportError: error,
    resetDownloadCampaignReport: reset,
  }
}

export function useCampaignVoucherReportURL(campaignId: string) {
  const { toastErrorWithoutTitle } = useToast()
  const { mutateAsync, status, data, error, reset, isLoading } = useMutation(
    async () => {
      const response = await fetchCampaignVoucherReportURL({
        campaignId,
      })
      const reportUrl = response?.link
      if (reportUrl) {
        openInNewTab(reportUrl)
      }
    },
    {
      onError: (axiosError: AxiosError) => {
        toastErrorWithoutTitle({
          message:
            axiosError.response?.status === 404
              ? 'Please wait 1 working day after transactions have started to download your voucher report.'
              : 'Oops, something went wrong.',
        })
      },
    }
  )

  const downloadCampaignVoucherReport = mutateAsync

  return {
    downloadCampaignVoucherReport,
    isDownloadCampaignVoucherReportLoading: isLoading,
    downloadCampaignVoucherReportStatus: status,
    downloadCampaignVoucherReportResponse: data,
    downloadCampaignVoucherReportError: error,
    resetDownloadCampaignVoucherReport: reset,
  }
}

export function useDownloadCampaignVoucherLinks(campaignId: string) {
  const { toastErrorWithoutTitle } = useToast()
  const { mutateAsync, data, error, isLoading } = useMutation(
    async () => {
      const response = await downloadCampaignVoucherLinks({
        campaignId,
      })
      const csvUrl = response.downloadUrl
      if (csvUrl) {
        openInNewTab(csvUrl)
      }
    },
    {
      onError: (axiosError: AxiosError) => {
        toastErrorWithoutTitle({
          message:
            axiosError.response?.status === 402
              ? 'Please wait until vouchers have been created to download your voucher links report.'
              : 'Oops, something went wrong.',
        })
      },
    }
  )

  return {
    downloadCampaignVoucherLinks: mutateAsync,
    isDownloadCampaignVoucherLinksLoading: isLoading,
    downloadCampaignVoucherLinksResponse: data,
    downloadCampaignVoucherLinksError: error,
  }
}

async function submitReportAPI({
  reportType,
  campaignId,
  startDate,
  endDate,
}: {
  reportType: DownloadDateRangeReportType
  campaignId: string
  startDate: string
  endDate: string
}) {
  switch (reportType) {
    case DownloadDateRangeReportType.TRANSACTIONS:
      return submitDownloadDateRangeTransactionReport({
        campaignId,
        startDate,
        endDate,
      })
    case DownloadDateRangeReportType.SETTLEMENTS:
      return submitDownloadDateRangeSettlementReport({
        campaignId,
        startDate,
        endDate,
      })
    default:
      throw new Error('Invalid report type')
  }
}

export function useSubmitDownloadDateRangeReport({
  reportType,
  campaignId,
  startDate,
  endDate,
  setShowNoEntriesError,
}: {
  reportType: DownloadDateRangeReportType
  campaignId: string
  startDate: string
  endDate: string
  setShowNoEntriesError: (message: string) => void
}) {
  const { toastError } = useToast()
  const { mutateAsync, error, isLoading } = useMutation(
    async () => {
      const response = await submitReportAPI({
        reportType,
        campaignId,
        startDate,
        endDate,
      })

      // Single date range report is available for download, eagerly open in new tab
      if (response.status === DownloadDateRangeReportStatus.completed) {
        setTimeout(() => {
          openInNewTab(response.link)
        }, 500) // Delay for 0.5 seconds to prevent weird UI
      }

      return response
    },
    {
      // eslint-disable-next-line consistent-return
      onError: (err: unknown) => {
        const typedError = err as AxiosError
        if (
          typedError?.response?.data?.error.code ===
          'download_date_range_report_campaign_no_entries_error'
        ) {
          setShowNoEntriesError(typedError?.response?.data.error.message)
        } else {
          return toastError({
            message:
              typedError?.response?.data.error.message ||
              'Oops an error has occurred',
          })
        }
      },
    }
  )

  return {
    submitDownloadDateRangeReport: mutateAsync,
    isSubmitDownloadDateRangeReportLoading: isLoading,
    submitDownloadDateRangeReportError: error,
  }
}

async function checkReportStatusAPI({
  reportType,
  campaignId,
  jobId,
}: {
  reportType: DownloadDateRangeReportType
  campaignId: string
  jobId: string
}) {
  switch (reportType) {
    case DownloadDateRangeReportType.TRANSACTIONS:
      return checkDownloadDateRangeTransactionReportJobStatus({
        campaignId,
        jobId,
      })
    case DownloadDateRangeReportType.SETTLEMENTS:
      return checkDownloadDateRangeSettlementReportJobStatus({
        campaignId,
        jobId,
      })
    default:
      throw new Error('Invalid report type')
  }
}

export function useCheckDownloadDateRangeReportJobStatus({
  reportType,
  campaignId,
  jobId,
}: {
  reportType: DownloadDateRangeReportType
  campaignId: string
  jobId: string
}) {
  const { toastError } = useToast()
  const queryClient = useQueryClient()
  const [isRefetchEnabled, setIsRefetchEnabled] = useState(true)

  const { data, error } = useQuery(
    ['checkDownloadDateRangeReportJobStatus', reportType, campaignId, jobId],
    async () => {
      const response = await checkReportStatusAPI({
        reportType,
        campaignId,
        jobId,
      })
      return response
    },
    {
      // Hook does not automatically run
      refetchInterval: isRefetchEnabled ? 2000 : false, // refetch every 2 seconds
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: isRefetchEnabled,
      onError: () => {
        setIsRefetchEnabled(false)
        toastError({ message: 'Oops an error has occurred' })
      },
      onSuccess: (successData) => {
        if (
          successData?.jobStatus === DownloadDateRangeJobStatus.success ||
          successData?.jobStatus === DownloadDateRangeJobStatus.failure
        ) {
          setIsRefetchEnabled(false)
          queryClient.invalidateQueries([reportType, campaignId])
        }

        if (successData?.jobStatus === DownloadDateRangeJobStatus.success) {
          openInNewTab(successData.reportUrl || '')
        }
      },
    }
  )

  return {
    downloadDateRangeJobStatus:
      data?.jobStatus || DownloadDateRangeJobStatus.not_started,
    reportUrl: data?.reportUrl || '',
    downloadDateRangeJobStatusError: error,
  }
}
