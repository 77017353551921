export const CAMPAIGN_NAV_TABS_KEYS = {
  DASHBOARD: 'dashboard',
  VOUCHERS: 'vouchers',
  MERCHANTS: 'merchants',
  SETTINGS: 'settings',
  HISTORY: 'history',
  METRICS: 'metrics',
}

// TODO: This have to be manually extended to match what is in the backend: campaigns -> eligiblity
export const CAMPAIGN_ELIGIBILITY = {
  SIGNUP_WHITELIST_NRIC: 'signup_whitelist_nric',
  SIGNUP_CONDITIONS_ADDRESS: 'signup_conditions_address',
  NO_SIGNUP: 'no_signup',
} as const
