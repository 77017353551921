import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import {
  VStack,
  Text,
  RadioGroup,
  Radio,
  HStack,
  Button,
  Select,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'

import FormInput from 'components/FormInput'
import { CONTACT_NUMBER_PREFIX } from 'constants/string'
import {
  PAYMENT_RELATED_STRING_CHARACTER_REGEX,
  VALID_BANK_NAMES,
} from 'constants/merchants'

import { FIELD_REQUIRED_MESSAGE } from 'constants/messages'
import styles from './MerchantUpdatePaymentDetailsForm.module.scss'

const BANK_TRANSFER_FORM_FIELDS = {
  MERCHANT_BANK_NAME: {
    id: 'paymentBankName',
    display: 'Bank Name',
    maxLength: 100,
    type: 'select',
    required: true,
    defaultValue: ({ paymentBankName }) => paymentBankName || '',
    selectOptions: Object.values(VALID_BANK_NAMES),
  },
  MERCHANT_BANK_ACCOUNT_HOLDER_NAME: {
    id: 'paymentBankAccountHolderName',
    display: 'Bank Account Holder Name',
    maxLength: 100,
    type: 'text',
    required: true,
    isInputValid: (input) => PAYMENT_RELATED_STRING_CHARACTER_REGEX.test(input),
    defaultValue: ({ paymentBankAccountHolderName }) =>
      paymentBankAccountHolderName || '',
  },
  MERCHANT_BANK_ACCOUNT_NUMBER: {
    id: 'paymentBankAccountNumber',
    display: 'Bank Account Number',
    maxLength: 100,
    type: 'text',
    required: true,
    isInputValid: (input) => /^\d+$/.test(input),
    defaultValue: ({ paymentBankAccountNumber }) =>
      paymentBankAccountNumber || '',
  },
}

const PAYNOW_MOBILE_NUMBER_FORM_FIELDS = {
  MERCHANT_PAYNOW_MOBILE_NUMBER: {
    id: 'paymentPaynowMobileNumber',
    display: 'PayNow Mobile Number (8 digits)',
    maxLength: 100,
    type: 'text',
    required: true,
    defaultValue: ({ paymentPaynowMobileNumber }) => {
      if (_.startsWith(paymentPaynowMobileNumber, CONTACT_NUMBER_PREFIX)) {
        return paymentPaynowMobileNumber.substring(CONTACT_NUMBER_PREFIX.length)
      }
      return paymentPaynowMobileNumber
    },
  },
}

const PAYNOW_NRIC_FORM_FIELDS = {
  MERCHANT_PAYNOW_NRIC: {
    id: 'paymentPaynowNric',
    display: 'PayNow NRIC',
    maxLength: 100,
    type: 'text',
    required: true,
    defaultValue: ({ paymentPaynowNric }) => paymentPaynowNric || '',
  },
}

const PAYNOW_UEN_FORM_FIELDS = {
  MERCHANT_PAYNOW_UEN: {
    id: 'paymentPaynowUen',
    display: 'PayNow UEN',
    maxLength: 100,
    type: 'text',
    required: true,
    defaultValue: ({ paymentPaynowUen }) => paymentPaynowUen || '',
  },
}

export default function MerchantUpdatePaymentDetailsForm({
  merchant,
  onPrimaryClick,
  isPrimaryLoading,
}) {
  // NOTE: docs for react-hook-forms are here https://react-hook-form.com/get-started#IntegratingwithUIlibraries
  // The hook manages state through the use of a controller (for a controlled component);
  // when the handleSubmit function is called, the wrapped function gets passed all the values of the form
  // as a single object with keys as the name prop of the controller
  const formMethods = useForm()
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = formMethods
  const preferredPaymentMethod = watch('paymentPreferredPaymentMethod')
  const paymentPaynowType = watch('paymentPaynowType')
  const onSubmit = (data) => {
    let fieldsToSend = { paymentPreferredPaymentMethod: preferredPaymentMethod }
    if (preferredPaymentMethod === 'BANK_TRANSFER') {
      // If preferred payment method is bank transfer, then add bank transfer related fields
      const bankTransferFields = _.pick(
        data,
        _.map(BANK_TRANSFER_FORM_FIELDS, (field) => field.id)
      )
      fieldsToSend = { ...fieldsToSend, ...bankTransferFields }
    } else if (preferredPaymentMethod === 'PAYNOW') {
      // If preferred payment method is paynow, then add PAYNOW related field depending
      // on user's choice of paynow proxy
      let paynowProxyField = {}
      if (paymentPaynowType === 'NRIC') {
        paynowProxyField = PAYNOW_NRIC_FORM_FIELDS
      } else if (paymentPaynowType === 'UEN') {
        paynowProxyField = PAYNOW_UEN_FORM_FIELDS
      } else if (paymentPaynowType === 'MOBILE_NUMBER') {
        paynowProxyField = PAYNOW_MOBILE_NUMBER_FORM_FIELDS
      }
      const dataToAdd = _.pick(
        data,
        _.map(paynowProxyField, (field) => field.id)
      )
      // Add back CONTACT_NUMBER_PREFIX if mobile number is being updated
      const mobileNumberFieldId = _.map(
        PAYNOW_MOBILE_NUMBER_FORM_FIELDS,
        (field) => field.id
      )
      if (_.has(dataToAdd, mobileNumberFieldId)) {
        dataToAdd[
          mobileNumberFieldId
        ] = `${CONTACT_NUMBER_PREFIX}${dataToAdd[mobileNumberFieldId]}`.trim()
      }
      fieldsToSend = { ...fieldsToSend, ...dataToAdd }
    }

    onPrimaryClick(fieldsToSend)
  }

  const defaultPaymentPaynowType = (() => {
    if (merchant.paymentPaynowMobileNumber) {
      return 'MOBILE_NUMBER'
    }
    if (merchant.paymentPaynowNric) {
      return 'NRIC'
    }
    if (merchant.paymentPaynowUen) {
      return 'UEN'
    }
    return undefined
  })()

  // Reset form to reflect any changes to merchant after it has been updated on
  // the backend.
  useEffect(() => {
    const allFields = {
      ...PAYNOW_UEN_FORM_FIELDS,
      ...PAYNOW_NRIC_FORM_FIELDS,
      ...BANK_TRANSFER_FORM_FIELDS,
      ...PAYNOW_MOBILE_NUMBER_FORM_FIELDS,
    }
    // Apply field.defaultValue to merchant.
    const defaultValues = _(allFields)
      .map((field) => [field.id, field.defaultValue(merchant)])
      .fromPairs()
      .value()
    reset({
      ...defaultValues,
      paymentPreferredPaymentMethod: merchant.paymentPreferredPaymentMethod,
      paymentPaynowType: defaultPaymentPaynowType,
    })
  }, [merchant, defaultPaymentPaynowType, reset])
  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.merchantUpdatePaymentDetailsFormContainer}
      >
        <VStack
          align="stretch"
          maxWidth="42.5rem"
          paddingY="40px"
          spacing="32px"
        >
          <VStack align="stretch" spacing="12px">
            <div className={styles.formLabelRow}>
              <Text textStyle="subhead1" color="neutral.900">
                Preferred Payment Method
              </Text>
            </div>
            <Controller
              name="paymentPreferredPaymentMethod"
              defaultValue={merchant.paymentPreferredPaymentMethod}
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  colorScheme="primary"
                  onChange={onChange}
                  value={value}
                >
                  <VStack align="start" spacing={0}>
                    <Radio value="BANK_TRANSFER">Bank Transfer</Radio>
                    <Radio value="PAYNOW">Paynow</Radio>
                  </VStack>
                </RadioGroup>
              )}
            />
          </VStack>
          {preferredPaymentMethod === 'BANK_TRANSFER' && (
            <>
              {_.map(
                BANK_TRANSFER_FORM_FIELDS,
                ({
                  id,
                  display,
                  maxLength,
                  type,
                  required,
                  defaultValue,
                  isInputValid,
                  selectOptions,
                }) => {
                  // Todo: Refactor to FormSelect component in the future
                  if (type === 'select') {
                    return (
                      <div key={id} className="input-group">
                        <FormControl isInvalid={errors[id]}>
                          <FormLabel htmlFor={id}>{display}</FormLabel>
                          <Select
                            background="white"
                            id={id}
                            placeholder="Select bank name"
                            {...formMethods.register(id, {
                              required: required
                                ? FIELD_REQUIRED_MESSAGE
                                : false,
                            })}
                          >
                            {_.map(selectOptions, (option) => (
                              <option value={option}>{option}</option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {errors[id] && errors[id].message}
                          </FormErrorMessage>
                        </FormControl>
                      </div>
                    )
                  }

                  return (
                    <div key={id} className="input-group">
                      <FormInput
                        label={display}
                        name={id}
                        defaultValue={defaultValue(merchant)}
                        registerOptions={{
                          required: required && FIELD_REQUIRED_MESSAGE,
                        }}
                        inputProps={{
                          maxLength,
                          type,
                        }}
                        isInputValid={isInputValid}
                      />
                    </div>
                  )
                }
              )}
            </>
          )}
          {preferredPaymentMethod === 'PAYNOW' && (
            <>
              <div key="paymentPreferredPaymentMethod" className="input-group">
                <div className={styles.formLabelRow}>
                  <Text textStyle="subhead1" color="neutral.900">
                    Paynow Identifier
                  </Text>
                </div>
                <Controller
                  name="paymentPaynowType"
                  control={control}
                  defaultValue={defaultPaymentPaynowType}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      colorScheme="primary"
                      onChange={onChange}
                      value={value}
                    >
                      <VStack align="start" spacing={0}>
                        <Radio value="NRIC">NRIC</Radio>
                        <Radio value="MOBILE_NUMBER">Mobile number</Radio>
                        <Radio value="UEN">UEN</Radio>
                      </VStack>
                    </RadioGroup>
                  )}
                />
              </div>
              {paymentPaynowType === 'MOBILE_NUMBER' &&
                _.map(
                  PAYNOW_MOBILE_NUMBER_FORM_FIELDS,
                  ({
                    id,
                    display,
                    maxLength,
                    type,
                    required,
                    defaultValue,
                  }) => (
                    <div key={id} className="input-group">
                      <FormInput
                        label={display}
                        name={id}
                        defaultValue={defaultValue(merchant)}
                        registerOptions={{
                          required: required && FIELD_REQUIRED_MESSAGE,
                        }}
                        inputProps={{
                          maxLength,
                          type,
                        }}
                      />
                    </div>
                  )
                )}
              {paymentPaynowType === 'UEN' &&
                _.map(
                  PAYNOW_UEN_FORM_FIELDS,
                  ({
                    id,
                    display,
                    maxLength,
                    type,
                    required,
                    defaultValue,
                  }) => (
                    <div key={id} className="input-group">
                      <FormInput
                        label={display}
                        name={id}
                        defaultValue={defaultValue(merchant)}
                        registerOptions={{
                          required: required && FIELD_REQUIRED_MESSAGE,
                        }}
                        inputProps={{
                          maxLength,
                          type,
                        }}
                      />
                    </div>
                  )
                )}
              {paymentPaynowType === 'NRIC' &&
                _.map(
                  PAYNOW_NRIC_FORM_FIELDS,
                  ({
                    id,
                    display,
                    maxLength,
                    type,
                    required,
                    defaultValue,
                  }) => (
                    <div key={id} className="input-group">
                      <FormInput
                        label={display}
                        name={id}
                        defaultValue={defaultValue(merchant)}
                        registerOptions={{
                          required: required && FIELD_REQUIRED_MESSAGE,
                        }}
                        inputProps={{
                          maxLength,
                          type,
                        }}
                      />
                    </div>
                  )
                )}
            </>
          )}

          <HStack spacing="24px">
            <Button
              colorScheme="primary"
              isLoading={isPrimaryLoading}
              type="submit"
              variant="solid"
            >
              Save
            </Button>
            <Button
              colorScheme="primary"
              onClick={() => reset()}
              variant="link"
            >
              Cancel changes
            </Button>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  )
}

MerchantUpdatePaymentDetailsForm.propTypes = {
  merchant: PropTypes.objectOf(PropTypes.any).isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  isPrimaryLoading: PropTypes.bool.isRequired,
}
