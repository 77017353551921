import React, { useEffect } from 'react'
import { useCampaignContext } from 'routes/Campaigns/Campaign/context/CampaignContext'
import { DownloadDateRangeJobStatus } from 'services/RedeemApi/types'
import { DownloadDateRangeReportType } from 'components/DownloadReportModal/types'
import { useCheckDownloadDateRangeReportJobStatus } from 'hooks/CampaignReports'
import JobLoading from './components/JobLoading'
import JobCompleted from './components/JobCompleted'

type SubmittedJobModalProps = {
  reportType: DownloadDateRangeReportType
  jobId: string
  closeAndResetModalState: () => void
}

const DownloadDateRangeReportSubmitted = ({
  reportType,
  jobId,
  closeAndResetModalState,
}: SubmittedJobModalProps) => {
  const { campaignId } = useCampaignContext()

  const {
    downloadDateRangeJobStatus,
  } = useCheckDownloadDateRangeReportJobStatus({
    reportType,
    campaignId,
    jobId,
  })

  const isJobLoading =
    downloadDateRangeJobStatus === DownloadDateRangeJobStatus.not_started ||
    downloadDateRangeJobStatus === DownloadDateRangeJobStatus.started

  const isJobCompleted =
    downloadDateRangeJobStatus === DownloadDateRangeJobStatus.success ||
    downloadDateRangeJobStatus === DownloadDateRangeJobStatus.failure

  // To alert user on refresh/exit
  useEffect(() => {
    const confirmExit = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '' // This is required for older browsers
    }

    window.addEventListener('beforeunload', confirmExit)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', confirmExit)
    }
  }, [])

  if (isJobLoading) {
    return <JobLoading jobState={downloadDateRangeJobStatus} />
  }

  if (isJobCompleted) {
    return (
      <JobCompleted
        jobState={downloadDateRangeJobStatus}
        closeAndResetModalState={closeAndResetModalState}
      />
    )
  }

  return null
}

export default DownloadDateRangeReportSubmitted
