import React from 'react'
import DownloadReportModal from 'components/DownloadReportModal'
import { DownloadDateRangeReportType } from 'components/DownloadReportModal/types'

type DownloadSettlementReportsModalContainerProps = {
  isOpen: boolean
  onClose: () => void
  reports: any[]
  campaignId: string
}

const DownloadSettlementReportsModalContainer: React.FC<DownloadSettlementReportsModalContainerProps> = ({
  isOpen,
  onClose,
  reports,
  campaignId,
}) => {
  return (
    <DownloadReportModal
      isOpen={isOpen}
      onClose={onClose}
      reports={reports}
      reportType={DownloadDateRangeReportType.SETTLEMENTS}
      campaignId={campaignId}
    />
  )
}

export default DownloadSettlementReportsModalContainer
